var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "body"
    }
  }, [_vm.login_checked ? [_c('AppBar', {
    on: {
      "toggleDrawer": _vm.toggleDrawer
    }
  }), _c('NavDrawer', {
    ref: "drawer"
  }), _c('v-main', [_c('router-view')], 1)] : [_c('v-progress-linear', {
    attrs: {
      "color": "#18E",
      "indeterminate": ""
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }