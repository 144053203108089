<template>
  <div>
    <v-combobox ref='searchBox' :items='results' item-value='index' return-object no-filter filled rounded dense class='ml-4 mt-7' placeholder='Find something...' @update:search-input='search' @change='clickResult'>
      <template v-slot:prepend-inner>
        <div style='width: 30px;'>
          <v-progress-circular v-if='loading' indeterminate color="primary" size="22" width='2'/>
          <v-icon v-else>mdi-magnify</v-icon>
        </div>
      </template>
      <template v-slot:item="{item}">
        <v-row style='max-width:550px;' class='my-0 py-1'>

          <template v-if='item.type=="Contact"'>
            <v-col class='flex-grow-0 priamry py-0 my-0'>
              <v-chip label color='primary' small style='width:100%;'>{{item.data.contactType}}</v-chip>
            </v-col>
            <v-col class='flex-grow-1 py-0 my-0'>
              <div>
                <div class='font-weight-medium'>{{item.data.name_first}} {{item.data.name_last}}</div>
                <div class='text-caption font-weight-medium' v-if='item.data.contactType=="Customer"'>{{item.data.contactable.name}}</div>
                <div class='text-caption' v-if='item.data.note'>{{item.data.note}}</div>
                <div class='text-caption'>
                  <span class='mr-3' v-if='item.data.phone_1'>{{item.data.phone_1}}</span>
                  <span class='mr-3' v-if='item.data.phone_2'>{{item.data.phone_2}}</span>
                  <span class='mr-1' v-if='item.data.email'>{{item.data.email}}</span>
                </div>
              </div>
            </v-col>
          </template>

          <template v-if='item.type=="Address"'>
            <v-col class='flex-grow-0 priamry py-0 my-0'>
              <v-chip label color='primary' small style='width:100%;'>{{item.data.addressType}}</v-chip>
            </v-col>
            <v-col class='flex-grow-1 py-0 my-0'>
              <div>
                <div class='text-caption font-weight-medium' v-if='item.data.addressType=="Customer"'>{{item.data.addressable.name}}</div>
                <div class='font-weight-medium' v-if='item.data.addressType=="Job"'>
                  <div class='font-weight-medium primary--text'>{{item.data.addressable.number}}</div>
                  <div class='text-caption font-weight-medium' v-if='item.data.addressable.customer'>{{item.data.addressable.customer.name}}</div>
                </div>
                <div class='text-caption'>
                  <span class='mr-1' v-if='item.data.line_1'>{{item.data.line_1}}</span>
                  <span class='mr-1' v-if='item.data.line_2'>{{item.data.line_2}}</span>
                  <span class='mr-1' v-if='item.data.line_3'>{{item.data.line_3}}</span>
                  <span class='mr-1' v-if='item.data.suburb'>{{item.data.suburb}}</span>
                  <span class='mr-1' v-if='item.data.postcode'>{{item.data.postcode}}</span>
                  <span class='mr-1' v-if='item.data.country'>{{item.data.country}}</span>
                </div>
              </div>
            </v-col>
          </template>

          <template v-if='item.type=="Customer"'>
            <v-col class='flex-grow-0 priamry py-0 my-0'>
              <v-chip label color='primary' small style='width:100%;'>Customer</v-chip>
            </v-col>
            <v-col class='flex-grow-1 py-0 my-0'>
              <div>
                <div class='font-weight-medium primary--text'>{{item.data.name}}</div>
              </div>
            </v-col>
          </template>

          <template v-if='item.type=="Job"'>
            <v-col class='flex-grow-0 priamry py-0 my-0'>
              <v-chip label color='primary' small style='width:100%;'>Job</v-chip>
            </v-col>
            <v-col class='flex-grow-1 py-0 my-0'>
              <div>
                <div class='font-weight-medium primary--text'>
                  {{item.data.number}}
                  <span class='ml-2 text-caption secondary--text font-italic' v-if='item.data.stage'>({{item.data.stage.title}})</span>
                </div>
                <div class='text-caption'>
                  <div class='mr-1 font-weight-medium' v-if='item.data.customer'>{{item.data.customer.name}}</div>
                  <div class='mr-1 font-italic' v-if='item.data.description'>{{item.data.description}}</div>
                </div>
              </div>
            </v-col>
          </template>

        </v-row>
      </template>
    </v-combobox>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'SearchBar',
  components: {},
  props: [],
  data(){
    return {
      results: [],
      loading: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['NAVIGATE']),

    search(search){
      if(typeof(search) !== 'string') return;
      let data = { search };
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'search', data, autosave: 500 })
      .then(results => {
        this.loading = false;
        this.results = results.map((r,k) => ({ index: k, ...r }));
        //console.table(results.map(r => ({ type: r.type, ...r.data })));
      })
    },

    clickResult(result){
      if(!result) return;
      this.$refs.searchBox.reset();
      this.results = [];
      if(result.type == 'Contact'){
        if (result.data.contactType == 'Customer') this.NAVIGATE(`/dash/crm/${result.data.contactable.xid}`);
      }
      if(result.type == 'Address'){
        if (result.data.addressType == 'Customer') this.NAVIGATE(`/dash/crm/${result.data.addressable.xid}`);
        if (result.data.addressType == 'Job') this.NAVIGATE(`/dash/jobs/all/${result.data.addressable.xid}`);
      }
      if(result.type == 'Customer') this.NAVIGATE(`/dash/crm/${result.data.xid}`);
      if(result.type == 'Job') this.NAVIGATE(`/dash/jobs/all/${result.data.xid}`);
    },

  },
  created(){}
}
</script>
