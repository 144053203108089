<template>
  <v-navigation-drawer
    app clippedx
    id='navDrawer'
    v-model='open'
    :width="300"
    color='#FFF'
    border=0
  >
    <v-row class='my-0'>

      <v-col cols=12 class='pt-2 px-8 pb-5' align='center' v-if='true'>
        <v-img src="@/assets/mgg.svg" class=''></v-img>
      </v-col>
      <template v-for='item in items'>
        <v-col
          v-bind:key='item.page'
          v-if='!item.perm || PERMS.includes(item.perm)'
          cols=12
          :class='{active: page==item.page}'
          @click='navigate(item.page)'
          class='navItem'
        >
          <v-icon class='float-left mx-5' :class='item.icon'>{{item.icon}}</v-icon>
          <p class='float-left mb-0'>{{item.label}}</p>
        </v-col>
      </template>
    </v-row>
  </v-navigation-drawer>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navItem{
  cursor: pointer;
  .v-icon{ color: #114; }
  &:hover{
    background-color: #1143;
  }
  &.active{
    color: white;
    .v-icon{ color: white; }
    background-color: #18E;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'NavDrawer',
  components: {},
  props: [],
  data(){
    return {
      open: null,
    }
  },
  computed: {
    ...mapGetters("AUTH", ["PERMS"]),
    ...mapGetters("UI", ["JOB_STAGES"]),
    items(){
      let items = [
        { label: "Home", icon: "mdi-home", page: "", perm: null, },
        { label: "My Work", icon: "mdi-star", page: "work", perm: "MY_WORK", },
        { label: "File Inbox", icon: "mdi-folder-network", page: "filebox", perm: "FILEBOX", },
        { label: "Customers", icon: "mdi-account-group", page: "crm", perm: "CUSTOMERS" },
        { label: "Task Schedule", icon: 'mdi-chart-timeline', page: "schedule", perm: "MANAGE_SCHEDULE" },
        { label: "All Jobs", icon: 'mdi-briefcase', page: "jobs/all", perm: "JOBS" },
      ];
      this.JOB_STAGES.forEach(stage => {
        if(!stage.menu_icon) return;
        items.push({ label: stage.title, icon: stage.menu_icon, page: `jobs/${stage.xid}`, perm: "JOBS" });
      });
      return items;
    },
    page(){
      let route = this.$route.path.split('/');
      if(route[2] == "jobs") return `jobs/${route[3]}`;
      return route[2] ?? "";
    }
  },
  methods: {
    ...mapActions("UI", ["NAVIGATE"]),
    navigate(page){
      let link = `/dash/${page}`;
      if(this.$route.path != link) this.NAVIGATE(link);
    },
    toggle(){
      this.open = !this.open;
    }

  },
  created(){}
}
</script>
