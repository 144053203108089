var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "clippedx": "",
      "id": "navDrawer",
      "width": 300,
      "color": "#FFF",
      "border": "0"
    },
    model: {
      value: _vm.open,
      callback: function callback($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }, [_c('v-row', {
    staticClass: "my-0"
  }, [true ? _c('v-col', {
    staticClass: "pt-2 px-8 pb-5",
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/mgg.svg")
    }
  })], 1) : _vm._e(), _vm._l(_vm.items, function (item) {
    return [!item.perm || _vm.PERMS.includes(item.perm) ? _c('v-col', {
      key: item.page,
      staticClass: "navItem",
      class: {
        active: _vm.page == item.page
      },
      attrs: {
        "cols": "12"
      },
      on: {
        "click": function click($event) {
          return _vm.navigate(item.page);
        }
      }
    }, [_c('v-icon', {
      staticClass: "float-left mx-5",
      class: item.icon
    }, [_vm._v(_vm._s(item.icon))]), _c('p', {
      staticClass: "float-left mb-0"
    }, [_vm._v(_vm._s(item.label))])], 1) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }