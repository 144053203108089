var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-combobox', {
    ref: "searchBox",
    staticClass: "ml-4 mt-7",
    attrs: {
      "items": _vm.results,
      "item-value": "index",
      "return-object": "",
      "no-filter": "",
      "filled": "",
      "rounded": "",
      "dense": "",
      "placeholder": "Find something..."
    },
    on: {
      "update:search-input": _vm.search,
      "change": _vm.clickResult
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "width": "30px"
          }
        }, [_vm.loading ? _c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "primary",
            "size": "22",
            "width": "2"
          }
        }) : _c('v-icon', [_vm._v("mdi-magnify")])], 1)];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-row', {
          staticClass: "my-0 py-1",
          staticStyle: {
            "max-width": "550px"
          }
        }, [item.type == "Contact" ? [_c('v-col', {
          staticClass: "flex-grow-0 priamry py-0 my-0"
        }, [_c('v-chip', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "label": "",
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(_vm._s(item.data.contactType))])], 1), _c('v-col', {
          staticClass: "flex-grow-1 py-0 my-0"
        }, [_c('div', [_c('div', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.data.name_first) + " " + _vm._s(item.data.name_last))]), item.data.contactType == "Customer" ? _c('div', {
          staticClass: "text-caption font-weight-medium"
        }, [_vm._v(_vm._s(item.data.contactable.name))]) : _vm._e(), item.data.note ? _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(item.data.note))]) : _vm._e(), _c('div', {
          staticClass: "text-caption"
        }, [item.data.phone_1 ? _c('span', {
          staticClass: "mr-3"
        }, [_vm._v(_vm._s(item.data.phone_1))]) : _vm._e(), item.data.phone_2 ? _c('span', {
          staticClass: "mr-3"
        }, [_vm._v(_vm._s(item.data.phone_2))]) : _vm._e(), item.data.email ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.email))]) : _vm._e()])])])] : _vm._e(), item.type == "Address" ? [_c('v-col', {
          staticClass: "flex-grow-0 priamry py-0 my-0"
        }, [_c('v-chip', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "label": "",
            "color": "primary",
            "small": ""
          }
        }, [_vm._v(_vm._s(item.data.addressType))])], 1), _c('v-col', {
          staticClass: "flex-grow-1 py-0 my-0"
        }, [_c('div', [item.data.addressType == "Customer" ? _c('div', {
          staticClass: "text-caption font-weight-medium"
        }, [_vm._v(_vm._s(item.data.addressable.name))]) : _vm._e(), item.data.addressType == "Job" ? _c('div', {
          staticClass: "font-weight-medium"
        }, [_c('div', {
          staticClass: "font-weight-medium primary--text"
        }, [_vm._v(_vm._s(item.data.addressable.number))]), item.data.addressable.customer ? _c('div', {
          staticClass: "text-caption font-weight-medium"
        }, [_vm._v(_vm._s(item.data.addressable.customer.name))]) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "text-caption"
        }, [item.data.line_1 ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.line_1))]) : _vm._e(), item.data.line_2 ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.line_2))]) : _vm._e(), item.data.line_3 ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.line_3))]) : _vm._e(), item.data.suburb ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.suburb))]) : _vm._e(), item.data.postcode ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.postcode))]) : _vm._e(), item.data.country ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(item.data.country))]) : _vm._e()])])])] : _vm._e(), item.type == "Customer" ? [_c('v-col', {
          staticClass: "flex-grow-0 priamry py-0 my-0"
        }, [_c('v-chip', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "label": "",
            "color": "primary",
            "small": ""
          }
        }, [_vm._v("Customer")])], 1), _c('v-col', {
          staticClass: "flex-grow-1 py-0 my-0"
        }, [_c('div', [_c('div', {
          staticClass: "font-weight-medium primary--text"
        }, [_vm._v(_vm._s(item.data.name))])])])] : _vm._e(), item.type == "Job" ? [_c('v-col', {
          staticClass: "flex-grow-0 priamry py-0 my-0"
        }, [_c('v-chip', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "label": "",
            "color": "primary",
            "small": ""
          }
        }, [_vm._v("Job")])], 1), _c('v-col', {
          staticClass: "flex-grow-1 py-0 my-0"
        }, [_c('div', [_c('div', {
          staticClass: "font-weight-medium primary--text"
        }, [_vm._v(" " + _vm._s(item.data.number) + " "), item.data.stage ? _c('span', {
          staticClass: "ml-2 text-caption secondary--text font-italic"
        }, [_vm._v("(" + _vm._s(item.data.stage.title) + ")")]) : _vm._e()]), _c('div', {
          staticClass: "text-caption"
        }, [item.data.customer ? _c('div', {
          staticClass: "mr-1 font-weight-medium"
        }, [_vm._v(_vm._s(item.data.customer.name))]) : _vm._e(), item.data.description ? _c('div', {
          staticClass: "mr-1 font-italic"
        }, [_vm._v(_vm._s(item.data.description))]) : _vm._e()])])])] : _vm._e()], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }