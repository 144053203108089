<template>
  <v-app-bar
    app darkx xclipped-left
    colours="Y #FE0 LB #18E DB#114"
    xxcolor='#18E'
    color='white'
    elevation="3"
  >
    <v-app-bar-nav-icon class='hidden-lg-and-up' @click='$emit("toggleDrawer")' />
    <v-toolbar-title class='hidden-sm-and-down primary--text'>{{PAGE_TITLE}}</v-toolbar-title>
    <v-spacer />
    <v-toolbar-title style='width: 100%; max-width: 600px' class='mr-4'>
      <SearchBar />
    </v-toolbar-title>
    <v-spacer class='hidden-md-and-up' />
    <v-tooltip bottom>
      <template #activator="{on,attrs}">
      <div v-on="on" v-bind="attrs">
        <v-menu >
          <template #activator="{on,attrs}">
            <v-btn fab text v-on="on" v-bind="attrs">
              <v-avatar class="white--text text-h5" color="primary" @click="()=>{}">{{USER_INITIALS}}</v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click='NAVIGATE("/dash/settings")'><v-icon class="primary--text mr-4">mdi-cog</v-icon>Settings</v-list-item>
            <v-list-item link @click='LOGOUT'><v-icon class="primary--text mr-4">mdi-logout</v-icon>Sign Out</v-list-item>
          </v-list>
        </v-menu>
      </div>
      </template>
      {{USER.name_first}} {{USER.name_last}}
    </v-tooltip>
  </v-app-bar>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import SearchBar from '@/components/dash/SearchBar'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppBar',
  components: { SearchBar },
  props: [],
  data(){
    return {}
  },
  computed: {
    ...mapGetters('UI', ['PAGE_TITLE']),
    ...mapGetters('AUTH', ['USER', 'USER_INITIALS']),
  },
  methods: {
    ...mapActions('AUTH', ['LOGOUT']),
    ...mapActions('UI', ['NAVIGATE']),
  },
}
</script>
