<template>
  <div id='body'>
    <template v-if='login_checked'>
      <AppBar @toggleDrawer='toggleDrawer' />
      <NavDrawer ref='drawer' />
      <v-main>
        <router-view />
      </v-main>
    </template>
    <template v-else>
      <v-progress-linear color="#18E" indeterminate />
    </template>
  </div>
</template>

<style lang="scss" scoped>
#body{
  height: 100vh;
}
.v-main{
  height: 100%;
  background-color: #1141;
}
</style>
<style lang="scss">
html{
  overflow-y: hidden !important;
}
</style>


<script>
import AppBar from '@/components/dash/AppBar';
import NavDrawer from '@/components/dash/NavDrawer';
import { mapGetters, mapActions } from 'vuex';
//import Cookies from 'js-cookie';

export default {
  name: 'Dashboard',
  components: {
    AppBar, NavDrawer,
  },
  data(){ return {
    login_checked: false,
  }},
  computed: {
    ...mapGetters('AUTH', ['LOGGED_IN', 'PERMS']),
  },
  watch:{
    $route: { handler(){ let t = `Dashboard`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions('API', ['API']),
    ...mapActions('AUTH', ['LOGOUT', 'SET_PERMS', 'SET_USER']),
    ...mapActions('UI', ['SET_TITLE', 'NAVIGATE', 'NOTIFY']),
    toggleDrawer(){ this.$refs.drawer.toggle(); },
    checkLogin(){
      if(!this.LOGGED_IN){ this.$router.push('/login'); return; }
      this.API({ method: "GET", endpoint: 'auth/check' })
      .then(({ permissions, user }) => {
        this.SET_PERMS(permissions);
        this.SET_USER(user);
        this.login_checked = true;
      })
      .catch(this.LOGOUT);
    }
  },
  created(){
    this.checkLogin();
  },
}
</script>
