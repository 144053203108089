var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "darkx": "",
      "xclipped-left": "",
      "colours": "Y #FE0 LB #18E DB#114",
      "xxcolor": "#18E",
      "color": "white",
      "elevation": "3"
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "hidden-lg-and-up",
    on: {
      "click": function click($event) {
        return _vm.$emit("toggleDrawer");
      }
    }
  }), _c('v-toolbar-title', {
    staticClass: "hidden-sm-and-down primary--text"
  }, [_vm._v(_vm._s(_vm.PAGE_TITLE))]), _c('v-spacer'), _c('v-toolbar-title', {
    staticClass: "mr-4",
    staticStyle: {
      "width": "100%",
      "max-width": "600px"
    }
  }, [_c('SearchBar')], 1), _c('v-spacer', {
    staticClass: "hidden-md-and-up"
  }), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-menu', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "fab": "",
                  "text": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-avatar', {
                staticClass: "white--text text-h5",
                attrs: {
                  "color": "primary"
                },
                on: {
                  "click": function click() {}
                }
              }, [_vm._v(_vm._s(_vm.USER_INITIALS))])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.NAVIGATE("/dash/settings");
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("mdi-cog")]), _vm._v("Settings")], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": _vm.LOGOUT
          }
        }, [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("mdi-logout")]), _vm._v("Sign Out")], 1)], 1)], 1)], 1)];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.USER.name_first) + " " + _vm._s(_vm.USER.name_last) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }